var QGIV = QGIV || {};

QGIV.FacebookFundraiser = {
    // -------------------------------------------------------------------------
    // MARK: Properties
    // -------------------------------------------------------------------------
    appID: QGIV.Form.fbAppID,
    authResponse: null,
    registrantId: null, // Will hold the registrant id after Document Ready
    $fundraiserCta: $('.js-facebook-fundraiser-cta'), // The Fundraiser CTA element
    $dismissCtaBtn: $('.js-dismiss-fb-cta'), // Button to dismiss CTA element
    $connectBtn: $('.js-connect-facebook-fundraiser'), // Button to trigger Fundraiser creation
    $disconnectBtn: $('.js-disconnect-facebook-fundraiser'), // Button to show disconnect modal
    $infoBtn: $('.js-show-facebook-fundraiser-info'), // Button to show Fundraiser info modal
    $infoModal: $('.js-facebook-fundraiser-cta-modal'), // The Fundraiser info modal
    $detailsModal: $('.js-facebook-fundraiser-details-modal'), // The Fundraiser details modal
    $responseModal: $('.js-facebook-fundraiser-response-modal'), // The Fundraiser response modal
    $disconnectModal: $('.js-facebook-fundraiser-disconnect-modal'), // The Fundraiser disconnect modal
    state: { // Stores our state settings
        ctaHidden: false
    },

    // -------------------------------------------------------------------------
    // MARK: State/Local Storage manipulation
    // -------------------------------------------------------------------------
    /**
    * @private
    * @method setState
    * @memberof QGIV.FacebookFundraiser
    * @param {object} state The new state we are setting
    * @description Updates current state and saves the settings in local storage
    */
    setState: function (state) {
        var _this = this;

        _this.state = $.extend(_this.state, state);
        WebStorage.set('fbfundraiser_state_' + _this.registrantId, JSON.stringify(_this.state));

        // Trigger state change event handling
        $(document).trigger('FBFundraiser:StateChange');
    },

    /**
    * @private
    * @method getState
    * @memberof QGIV.FacebookFundraiser
    * @description Retrieves any stored state settings from local storage.
    *              Sets the default local storage state
    */
    getState: function () {
        var _this = this;
        var state = WebStorage.get('fbfundraiser_state_' + _this.registrantId);

        if (state) {
            _this.setState(JSON.parse(state));
        } else {
            _this.setState(_this.state);
        }
    },

    // -------------------------------------------------------------------------
    // MARK: Analytics
    // -------------------------------------------------------------------------
    /**
    * @private
    * @method trackEvent
    * @memberof QGIV.FacebookFundraiser
    * @param {string} eventName - The analytics event label
    * @param {string} [action] - Default is 'clicked'
    * @param {boolean} [addContext] - Default is true. Adds context to event label.
    * @description Handles analytics tracking events
    */
    trackEvent: function (eventName, action, addContext) {
        var _this = this;
        var action = action || 'clicked';
        var addContext = addContext !== undefined ? addContext : true;
        var context = _this.$fundraiserCta.attr('data-cta-context') || '';

        if (addContext && context) eventName = eventName + ' in ' + context.toUpperCase();

        QGIV.Analytics.GA.send({
            hitType: 'event',
            eventCategory: 'Peer-to-Peer',
            eventAction: action,
            eventLabel: eventName
        });
        QGIV.Analytics.FB.logEvent(eventName);
    },

    // -------------------------------------------------------------------------
    // MARK: API calls and helpers
    // -------------------------------------------------------------------------
    /**
    * @private
    * @method sendFundraiserPermissions
    * @memberof QGIV.FacebookFundraiser
    * @param {object} data FB user data required to make a FB Fundraiser
    * @description Sends FB user data to our backend in order to create a Fundraiser.
    */
    sendFundraiserPermissions: function (data) {
        var _this = this;
        return $.fn.qpost({
            url: url('pfp_facebook_fundraiser', [_this.registrantId]),
            data: data,
        });
    },

    /**
    * @private
    * @method handleFundraiserCreation
    * @memberof QGIV.FacebookFundraiser
    * @description Get's the data required for us to create a FB Fundraiser
    * @returns {jQuery.deffered.promise}
    */
    handleFundraiserCreation: function () {
        var _this = this;
        var dfd = new $.Deferred();
        var $titleInput = _this.$detailsModal.find('.js-facebook-fundraiser-title');
        var $descriptionInput = _this.$detailsModal.find('.js-facebook-fundraiser-description');
        var $goalInput = _this.$detailsModal.find('.js-facebook-fundraiser-goal');

        $(document).trigger('FBFundraiser:CreateFundraiser:Start');

        FB.api('/me', {
            fields: 'name, first_name, last_name, email, permissions'
        }, function (response) {
            var userId = _this.authResponse.userID;
            var token = _this.authResponse.accessToken;
            var email = response.email;
            var firstName = response.first_name;
            var lastName = response.last_name;
            var permissions = '';
            var title = $titleInput.val();
            var description = $descriptionInput.val();
            var goal = $goalInput.length > 0 ? QGIV.Utilities.toFloat($goalInput.val().split(QGIV.Form.currency.symbol)[1]) : false;

            if (response.permissions && response.permissions.data) {
                permissions = JSON.stringify(response.permissions.data);
            }

            var fbData = {
                fb_signin: 1,
                userId: userId,
                token: token,
                email: email,
                firstName: firstName,
                lastName: lastName,
                permissions: permissions,
                title: title,
                description: description,
                success: true
            };

            if ($goalInput.is(':visible')) {
                fbData.goal = goal;
            }

            // Handle backend call to create the fundraiser
            _this.sendFundraiserPermissions(fbData)
                .done(function (data) {
                    // Pass backend data through
                    dfd.resolve(data);
                }).fail(function () {
                    // Give a clue that the ajax call failed
                    dfd.reject();
                });
        });

        return dfd.promise()
            .done(function (response) {
                if (response.success) {
                    $(document).trigger('FBFundraiser:CreateFundraiser:Success', response.fundraiserId);
                } else {
                    if (response.errors.length > 0) {
                        $(document).trigger('FBFundraiser:CreateFundraiser:Failure', response.errors);
                    } else {
                        $(document).trigger('FBFundraiser:CreateFundraiser:Failure', ['An error occurred']);
                    }
                }
            })
            .fail(function () {
                $(document).trigger('FBFundraiser:CreateFundraiser:Failure');
            });
    },

    /**
    * @private
    * @method handleFundraiserDetails
    * @description Opens the details modal and listens for when user completes/exits the modal
    */
    handleFundraiserDetails: function () {
        var _this = this;
        var dfd = new $.Deferred();

        _this.$detailsModal.data('qg_modal').showModal();

        $(document).on('FBFundraiser:FundraiserDetails:Accept', function () {
            $(document).off('FBFundraiser:FundraiserDetails:Accept', 'FBFundraiser:FundraiserDetails:Reject');
            dfd.resolve();
        });

        $(document).on('FBFundraiser:FundraiserDetails:Reject', function () {
            $(document).off('FBFundraiser:FundraiserDetails:Accept', 'FBFundraiser:FundraiserDetails:Reject');
            dfd.reject();
        });

        return dfd.promise();
    },

    /**
    * @private
    * @method handleFbAuthentication
    * @memberof QGIV.FacebookFundraiser
    * @description Recursively repeats flow until proper authentication response data is passed in.
    * @returns {jQuery.Deferred.promise}
    */
    handleFbAuthentication: function () {
        var _this = this;
        var dfd = new $.Deferred();
        var fbScopeOptions = {
            scope: 'public_profile,email,manage_fundraisers',
            return_scopes: true
        };

        /**
        * @function getPermissions
        * @description Fetches the permissions for a logged in FB user.
        *              Only use if you know that you have a logged in FB user.
        * @return {jQuery.Deferred.promise}
        */
        var getPermissions = function () {
            var _dfd = new $.Deferred();
            var userId = FB.getUserID();
            var endpoint = '/' + userId + '/permissions';

            FB.api(endpoint, function(response) {
                if (response && !response.error) {
                    _dfd.resolve(response.data);
                } else {
                    _dfd.reject(false);
                }
            });

            return _dfd.promise();
        };

        /**
        * @function checkPermissions
        * @param {object} response FB login response
        * @description Checks if a FB user has manage_fundraiser scope.
        * @returns {boolean}
        */
        var checkPermissions = function (permissions) {
            var hasPermissions = permissions.filter(function(x){
                return x.permission === 'manage_fundraisers' && x.status === 'granted';
            }).length > 0;
            return hasPermissions;
        };

        /**
        * @function login
        * @description Prompts user to login to Facebook
        */
        var login = function() {
            FB.login(function (_response) {
                if (_response.authResponse) {
                    authenticate(_response);
                }
                // user cancelled login process
                // do nothing
            }, fbScopeOptions);
        };

        /**
        * @function handleAuthenticatedResponse
        * @param {object} response FB login response
        * @description Handles facebook authResponse data
        *              Forces re-authentication if access token has expired
        *              Access token expires after 90 days
        */
        var handleAuthenticatedResponse = function (response) {
            if (response && response.status === 'connected') {
                if ((response.authResponse.data_access_token_expires * 1000) <= Date.now()) login();
                else {
                    _this.authResponse = response.authResponse;
                    dfd.resolve();
                }
            }
        };

        /**
        * @function authenticate
        * @description Authenticates a user's facebook account with our app.
        * @see https://developers.facebook.com/docs/reference/javascript/FB.getLoginStatus/
        */
        var authenticate = function (response) {
            if (response.status === 'connected' && response.authResponse ) {
                // The user is logged in and has authenticated our
                // app, and response.authResponse supplies
                // the user's ID, a valid access token, a signed
                // request, and the time the access token
                // and signed request each expire.
                // Check permissions for `manage_fundraiser`
                getPermissions()
                    .then(function(permissions){
                        if (checkPermissions(permissions)) {
                            // User is logged in and has authenticated our app.
                            handleAuthenticatedResponse(response);
                        } else {
                            // User is logged in and needs to authenticate our app.
                            login();
                        }
                    });
            } else if (response.status === 'not_authorized') {
                // User is logged in but hasn't authorized our application.
                // Force a call to login with proper scope.
                login();
            } else {
                // The user isn't logged in to Facebook.
                // Force a call to login from login window.
                login();
            }
        };

        if (FB instanceof Object) {
            FB.getLoginStatus(function (response) {
                authenticate(response);
            });
        } else {
            console.error('Facebook sdk is not available.');
            dfd.reject(false);
        }

        return dfd.promise();
    },

    /**
    * @public
    * @method createFundraiser
    * @memberof QGIV.FacebookFundraiser
    * @description Authenticates a user with FB, then gathers appropriate FB data
    *              to pass to our backend and create a FB Fundraiser on their behalf.
    */
    createFundraiser: function () {
        var _this = this;

        _this.handleFbAuthentication()
            .then(function () {
                return _this.handleFundraiserDetails();
            })
            .then(function () {
                return _this.handleFundraiserCreation();
            });
    },

    /**
    * @private
    * @method disconnectFundraiser
    * @memberof QGIV.FacebookFundraiser
    * @description Makes API call to disconnect a FB Fundraiser from the Qgiv DB.
    */
    disconnectFundraiser: function () {
        var _this = this;

        return $.fn.qpost({
            url: url('pfp_dashboard', [_this.registrantId]),
            data: {
                clientRequestToDeleteFundraiser: 1
            }
        });
    },

    // -------------------------------------------------------------------------
    // MARK: Event Handling
    // -------------------------------------------------------------------------
    /**
    * @private
    * @method handleStateChange
    * @memberof QGIV.FacebookFundraiser
    * @description Event handling function for state change events
    */
    handleStateChange: function () {
        var _this = this;

        if (_this.state.ctaHidden && _this.$fundraiserCta.attr('data-cta-context') !== 'receipt') {
            _this.$fundraiserCta.addClass('hide-content');
        } else {
            _this.$fundraiserCta.removeClass('hide-content');
        }
    },

    /**
    * @private
    * @method bind
    * @memberof QGIV.FacebookFundraiser
    * @description Bind event handling functions to event listeners
    */
    bind: function () {
        var _this = this;
        var $loadingModal = $('.js-loading-modal');

        _this.$connectBtn.on('click', function () {
            _this.createFundraiser();
            _this.trackEvent('FB Fundraiser Button Click', 'clicked');
        });

        _this.$disconnectBtn.on('click', function() {
            _this.$disconnectModal.data('qg_modal').showModal();
        });

        _this.$infoBtn.on('click', function () {
            _this.$infoModal.data('qg_modal').showModal();
            _this.trackEvent('FB Fundraiser Learn Click', 'clicked');
        });

        _this.$fundraiserCta.on('click', '.js-view-fundraiser', function () {
            _this.trackEvent('Go to FB Fundraiser Button Click', 'clicked', false);
        });

        $(document).on('FBFundraiser:CreateFundraiser:Start', function () {
            $loadingModal.find('.js-loading-modal-text').html('Saving Fundraiser Info').end()
                .data('qg_modal').showModal();
        });

        $(document).on('FBFundraiser:CreateFundraiser:Success', function (e, fundraiserId) {
            $loadingModal.data('qg_modal').closeModal();
            _this.$responseModal.find('.js-success-content').removeClass('hide-content').end()
                .find('.js-failure-content').addClass('hide-content').end()
                .find('.ui-modal-actions').removeClass('hide-content');
            _this.$responseModal.attr('data-fundraiser-id', fundraiserId)
                .data('qg_modal').showModal();
            _this.trackEvent('FB Fundraiser Created', 'created', false);
        });

        $(document).on('FBFundraiser:CreateFundraiser:Failure', function (e, errors) {
            $loadingModal.data('qg_modal').closeModal();
            if (errors) {
                var keys = Object.keys(errors);
                var errs = keys.map(function (k) {
                    return errors[k];
                }).join('');
                _this.$responseModal.find('.js-failure-content-errors').html(errs);
            }
            _this.$responseModal.find('.js-success-content').addClass('hide-content').end()
                .find('.js-failure-content').removeClass('hide-content').end()
                .find('.ui-modal-actions').addClass('hide-content').end()
                .data('qg_modal').showModal();
        });

        _this.$dismissCtaBtn.on('click', function () {
            _this.setState({
                ctaHidden: true
            });
            _this.trackEvent('FB Fundraiser Dismissed', 'dismissed');
        });

        $(document).on('FBFundraiser:StateChange', function () {
            _this.handleStateChange();
        });

        // plugins
        AutoNumericNumber('.js-facebook-fundraiser-goal', {
            currencySymbol: QGIV.Form.currency.symbol,
            digitGroupSeparator: ',',
            decimalPlaces: 2,
            minimumValue: 1.00,
            maximumValue: 100000000.00
        });

        // Validation for fundraiser description
        _this.$detailsModal.validate();

        // Character counter for fb fundraiser title input
        _this.$detailsModal.find('.js-facebook-fundraiser-title-charcount').charCounter({
            charlimit: 70,
            inputSelector: '.js-facebook-fundraiser-title'
        });
    },

    // -------------------------------------------------------------------------
    // MARK: Lifecycle
    // -------------------------------------------------------------------------
    /**
    * @private
    * @method initModals
    * @memberof QGIV.FacebookFundraiser
    * @description Starts modals
    */
    initModals: function () {
        var _this = this;

        _this.$infoModal.modal({
            bgClose: false,
            accept: function () {
                _this.$infoModal.data('qg_modal').closeModal();
                _this.createFundraiser();
            },
            decline: function () {
                _this.$infoModal.data('qg_modal').closeModal();
            }
        });

        _this.$detailsModal.modal({
            bgClose: false,
            onShow: function () {
                var placeholder = parseInt(_this.$detailsModal.find('.js-facebook-fundraiser-goal').attr('placeholder'));
                AutoNumeric.getAutoNumericElement(document.getElementById('facebook_fundraiser_goal')).set(placeholder);
            },
            accept: function () {
                var errors = _this.$detailsModal.data('qg_validation').validateForm();
                if (errors.length > 0) {
                    Message.target = '.js-facebook-fundraiser-details-modal .js-errors';
                    Message.post('Please fix errors then try again:', 'error');
                } else {
                    $(document).trigger('FBFundraiser:FundraiserDetails:Accept');
                    _this.$detailsModal.data('qg_modal').closeModal();
                }
            },
            decline: function () {
                $(document).trigger('FBFundraiser:FundraiserDetails:Reject');
                _this.$detailsModal.data('qg_modal').closeModal();
            }
        });

        _this.$responseModal.modal({
            bgClose: false,
            width: 'width-70',
            accept: function () {
                var fundraiserId = _this.$responseModal.attr('data-fundraiser-id');
                if (fundraiserId) {
                    var href = 'https://facebook.com/' + fundraiserId;
                    _this.$responseModal.find('.bind-accept').attr('href', href)
                        .one('click', function () {
                            _this.trackEvent('Go to FB Fundraiser Button Click', 'clicked', false);
                        });
                }
                _this.$responseModal.data('qg_modal').closeModal();
                window.location.reload();
            },
            decline: function () {
                _this.$responseModal.data('qg_modal').closeModal();
                window.location.reload();
            }
        });

        _this.$disconnectModal.modal({
            bgClose: false,
            width: 'width-70',
            accept: function() {
                var $loadingModal = $('.js-loading-modal');
                $loadingModal.data('qg_modal').showModal();
                _this.disconnectFundraiser()
                    .then(function(response){
                        if (response.success){
                            window.location.reload();
                        }
                    })
                    .fail(function(){
                        console.error('An error occured when disconnecting the facebook fundraiser');
                    })
                    .always(function() {
                        $loadingModal.data('qg_modal').closeModal();
                    });
            },
            decline: function() {
                _this.$disconnectModal.data('qg_modal').closeModal();
            }
        });
    },

    /**
    * @public
    * @method init
    * @memberof QGIV.FacebookFundraiser
    * @param {string} registrantId
    * @description Kick off FacebookFundraiser
    */
    init: function (registrantId) {
        var _this = this;

        _this.registrantId = registrantId;
        _this.initModals();

        // init facebook sdk
        UI.vendor.facebook(function () {
            // Wait for facebook sdk to load before binding event listeners
            _this.bind();
            _this.getState();
            _this.trackEvent('FB Fundraiser Button Shown', 'displayed');
            if (_this.$fundraiserCta.attr('data-cta-context') === 'receipt') {
                _this.trackEvent('Participant Registration Complete', 'completed', false);
            }
        }, _this.appID);
    }
}

// -------------------------------------------------------------------------
// MARK: Document Ready
// -------------------------------------------------------------------------
$(document).ready(function () {
    var registrantId = $('#registrant-id').val();
    QGIV.FacebookFundraiser.init(registrantId);
});